import { Control, UseFormTrigger } from "react-hook-form";
import { AutocompleteForm } from "../../../../components/controls/AutocompleteForm";
import { IUser } from "../../../../models/user";
import { debounce, set } from "lodash";
import { ITag, ITagReq } from "../../../../models/user";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import UserService from "../../../../services/api/user.service";
import _debounce from "lodash/debounce";
import { TagType } from "../../../../models/common/models.enum";
import useAxios from "../../../../components/UseAxios/useAxios";
import { CircularProgress, Typography } from "@mui/material";
import { UniqueIdentifier } from "@dnd-kit/core";

interface Props {
  name: string;
  control?: Control<any, any>;
  errors: any;
  type?: TagType;
  store?: "workflow" | "stage";
  storeId?: UniqueIdentifier;
  isError: boolean;
  placeholder: string | undefined;
  multiple?: boolean;
  limit?: number;
  optionDisabled?: IUser[];
  defaultValues?: ITag[];
  trigger?: UseFormTrigger<any>;
}

export const TagInput = ({
  name,
  control,
  errors,
  isError,
  multiple,
  limit,
  placeholder,
  optionDisabled,
  type,
  store,
  storeId,
  defaultValues,
  trigger
}: Props) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<ITag[]>([]);
  const [noOptionsText, setnoOptionsText] = useState<ReactNode | undefined>();
  const optionDisabledItems: ITag[] | undefined =
    optionDisabled &&
    optionDisabled?.map(
      option =>
        ({
          id: option.id,
          username: option.userName,
          fullName: option.fullName,
          type: TagType.User
        }) as ITag
    );

  const search = useAxios<ITag[]>({ loading: "OnRequest" });

  const getTags = async (value: string) => {
    if (value !== "") {
      search.reset();
      let request: ITagReq = {
        CurrenPage: 1,
        PageSize: 10,
        KeySearch: value
      };
      if (type !== undefined) request.Type = type;

      if (store === "workflow" && storeId) {
        search.request(
          UserService.getUserOrDepartmentByWorkflowId(request, storeId)
        );
      }
      if (store === "stage" && storeId) {
        search.request(
          UserService.getUserOrDepartmentByStageId(request, storeId)
        );
      }

      if (!store) {
        search.request(UserService.getUserOrDepartment(request));
      }
    } else {
      setOptions([]);
      setLoading(false);
    }
  };

  const [handleTagSearch] = useState(() => _debounce(getTags, 500));

  useEffect(() => {
    setnoOptionsText(
      <center>
        <CircularProgress size={24} />
      </center>
    );

    handleTagSearch(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (search.isSuccess) {
      setOptions(search.data ?? []);
      setnoOptionsText(undefined);
    }
    if (search.error) {
      setnoOptionsText(
        <Typography key="noOptionsText">{"Đã có lỗi xảy ra"}</Typography>
      );
    }
  }, [search.isSuccess, search.error]);

  return (
    <AutocompleteForm<ITag>
      multiple={multiple ?? true}
      name={name}
      control={control}
      errors={errors}
      limit={limit}
      defaultValue={defaultValues}
      inputValue={inputValue}
      onInputChanged={(_, v) => setInputValue(v)}
      id={"tags-" + name}
      isError={isError}
      options={options}
      filterSelectedOptions={true}
      noOptionsText={noOptionsText}
      open={inputValue !== undefined && inputValue.length >= 1}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionTag={option => option.username}
      getOptionLabel={option => option.fullName + option.username}
      renderOption={option => <p> {option.fullName}</p>}
      size={"small"}
      placeholder={placeholder}
      optionDisabled={optionDisabledItems}
      loading={loading}
      trigger={trigger}
    />
  );
};
