import { ROUTE_PATH } from "./common/constants/app.constant";
import { RouteObject } from "react-router";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

import { Navigate } from "react-router-dom";
import { INavItem } from "./models/app";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import UnAuthLayout from "./components/dashboard/UnAuthLayout";

import Material from "./pages/Material/Material";
import TimeoffIcon from "./assets/images/sidebar/icon_timeoff_line_grey.svg";
import TimeoffIconActive from "./assets/images/sidebar/icon_timeoff_active.svg";
import WorkflowIcon from "./assets/images/sidebar/icon_workflow_line_grey.svg";
import WorkflowIconActive from "./assets/images/sidebar/icon_workflow_active.svg";
import MyProjectIcon from "./assets/images/sidebar/icon_myproject_line_grey.svg";
import MyProjectIconActive from "./assets/images/sidebar/icon_myproject_active.svg";
import ScheduleIcon from "./assets/images/common/schedule_outline.svg";
import ScheduleIconActive from "./assets/images/common/schedule_active.svg";
import ApproveIcon from "./assets/images/common/approve.svg";
import ApproveIconActive from "./assets/images/common/approve_active.svg";
import WorkflowManagement from "./pages/WorkflowManagement/workflow_manage";
import ManageProject from "./pages/ManageProject/ManageProject";
import Account from "./pages/Account/Account";
import Member from "./pages/Member/Member";
import AccountInfo from "./pages/AccountInfo/AccountInfo";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LanRoundedIcon from "@mui/icons-material/LanRounded";
import Notification from "./pages/Notification/Notification";
import TaskDetail from "./pages/ManageProject/Task/components/JobDetail/JobDetail";
import Department from "./pages/Department/Department";
import DetailDepartment from "./pages/Department/components/DetailDepartment/DetailDepartment";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import FileReviewPage from "./pages/ManageProject/FileViewer";
import Booking from "./pages/Booking/Booking";
import TimeOffPage from "./pages/TimeOff/TimeOff";
import {
  DepartMentPermissionModel,
  FeatureModel,
  HRPermissionModel,
  MeetingRoomSchedulePermissionModel
} from "./models/common/models.enum";
import { IFeaturePermission } from "./models/user/models.roles";
import Approve from "./pages/Booking/Approve";

export const sidebarItems = (features?: IFeaturePermission[]): INavItem[] => {
  const WORKFLOWS: INavItem = {
    title: "QUAN TRỌNG",
    code: "Important",
    children: [
      {
        title: "Quản lý quy trình",
        code: "Quản lý quy trình",
        href: ROUTE_PATH.WORKFLOWS,
        icon: { default: WorkflowIcon, active: WorkflowIconActive }
      },
      {
        title: "Nghỉ phép",
        code: "TimeOff",
        href: ROUTE_PATH.TIMEOFF,
        icon: { default: TimeoffIcon, active: TimeoffIconActive }
      }
    ]
  };

  var MEETING: INavItem = {
    title: "Meeting",
    children: [
      {
        title: "Lịch họp",
        code: "Lịch họp",
        href: ROUTE_PATH.BOOKING,
        icon: { default: ScheduleIcon, active: ScheduleIconActive }
      }
    ]
  };
  if (
    features?.find(
      feature =>
        feature.feature === FeatureModel.MeetingRoomSchedule &&
        feature.permissions.includes(
          MeetingRoomSchedulePermissionModel.AdminUpdateStatus
        )
    )
  ) {
    MEETING = {
      ...MEETING,
      children: [
        ...(MEETING?.children ?? []),
        {
          title: "Phê duyệt",
          code: "Phê duyệt",
          href: ROUTE_PATH.APPROVE,
          icon: { default: ApproveIcon, active: ApproveIconActive }
        }
      ]
    };
  }

  const MATERIAL: INavItem = {
    title: "Material",
    children: [
      {
        title: "Material",
        code: "Material",
        href: ROUTE_PATH.MATERIAL,
        icon: { default: MyProjectIcon, active: MyProjectIconActive }
      }
    ]
  };

  let navs: INavItem[] = [WORKFLOWS, MEETING];

  if (process.env.NODE_ENV === "development") {
    navs.push(MATERIAL);
    // navs.push(DEVELOPMENT);
  }

  return navs;
};

export const accountSidebarItems = (features?: IFeaturePermission[]) => {
  const USER = {
    icon: {
      default: <AccountCircleRoundedIcon sx={{ color: "GrayText" }} />,
      active: <AccountCircleRoundedIcon />
    },
    text: "Cá nhân",
    href: ROUTE_PATH.ACCOUNT
  };

  // const NOTIFICATION = {
  //   icon: {
  //     default: <NotificationsRoundedIcon sx={{ color: "GrayText" }} />,
  //     active: <NotificationsRoundedIcon />
  //   },
  //   text: "Thông báo",
  //   href: ROUTE_PATH.ACCOUNT_NOTI
  // };

  const MEMBER = {
    icon: {
      default: <SupervisorAccountRoundedIcon sx={{ color: "GrayText" }} />,
      active: <SupervisorAccountRoundedIcon />
    },
    text: "Thành viên",
    href: ROUTE_PATH.ACCOUNT_MEMBER
  };

  const GROUP = {
    icon: {
      default: <LanRoundedIcon sx={{ color: "GrayText" }} />,
      active: <LanRoundedIcon />
    },
    text: "Nhóm",
    href: ROUTE_PATH.ACCOUNT_DEPARTMENT
  };

  const HOME = {
    icon: {
      default: <HomeRoundedIcon sx={{ color: "GrayText" }} />,
      active: <HomeRoundedIcon />
    },
    text: "Trang chủ",
    href: ROUTE_PATH.INDEX
  };

  let navs = [USER];

  if (process.env.NODE_ENV === "development") {
  }
  if (
    features?.find(
      feature =>
        feature.feature === FeatureModel.HR &&
        feature.permissions.includes(
          HRPermissionModel.ViewListUser ||
            HRPermissionModel.ViewUserRegisterTimeOff
        )
    )
  ) {
    navs.push(MEMBER);
  }
  if (
    features?.find(
      feature =>
        feature.feature === FeatureModel.Department &&
        feature.permissions.includes(
          DepartMentPermissionModel.View || DepartMentPermissionModel.ViewAll
        )
    )
  ) {
    navs.push(GROUP);
  }
  navs.push(HOME);

  return navs;
};

const appRoutes = (): RouteObject[] => {
  let routes: RouteObject[] = [
    {
      path: ROUTE_PATH.INDEX,
      element: <DashboardLayout />,
      children: [
        { path: "*", element: <Navigate to={ROUTE_PATH.NOTFOUND} /> },
        {
          path: ROUTE_PATH.INDEX,
          element: <Navigate to={ROUTE_PATH.WORKFLOWS} />
        },
        {
          path: ROUTE_PATH.MATERIAL,
          element: <Material />
        },

        {
          path: ROUTE_PATH.WORKFLOWS,
          element: <WorkflowManagement />
        },
        {
          path: ROUTE_PATH.PROJECTS,
          element: <ManageProject />
        },
        {
          path: ROUTE_PATH.TASK,
          element: <TaskDetail />
        },
        {
          path: ROUTE_PATH.APPROVE,
          element: <Approve />
        },
        {
          path: ROUTE_PATH.TIMEOFF,
          element: <TimeOffPage />
        },
        {
          path: ROUTE_PATH.BOOKING,
          element: <Booking />
        }
      ]
    },

    {
      path: ROUTE_PATH.INDEX,
      element: <UnAuthLayout />,
      children: [
        {
          path: ROUTE_PATH.LOGIN,
          element: <Login />
        },
        { path: "*", element: <Navigate to={ROUTE_PATH.LOGIN} /> },
        {
          path: ROUTE_PATH.RESOURCE,
          element: <FileReviewPage />
        }
      ]
    },
    {
      path: ROUTE_PATH.NOTFOUND,
      element: <NotFound />
    },
    {
      path: ROUTE_PATH.ACCOUNT,
      element: <Account />,
      children: [
        { path: "*", element: <Navigate to={ROUTE_PATH.NOTFOUND} /> },
        {
          path: ROUTE_PATH.ACCOUNT,
          element: <AccountInfo />
        },
        {
          path: ROUTE_PATH.ACCOUNT_NOTI,
          element: <Notification />
        },
        {
          path: ROUTE_PATH.ACCOUNT_MEMBER,
          element: <Member />
        },
        {
          path: ROUTE_PATH.ACCOUNT_DEPARTMENT,
          element: <Department />
          // children: [

          // ]
        },
        {
          path: `${ROUTE_PATH.ACCOUNT_DEPARTMENT}/:id`,
          element: <DetailDepartment />
        }
      ]
    }
  ];

  return routes;
};

export default appRoutes;
