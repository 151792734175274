import { Box, styled } from "@mui/material";

export const BoardProjectWrapper = styled(Box)(() => ({
    maxWidth: "100%",
    overflow: "auto",
    height: "calc(100% - 80px)",
    position: "relative",



}));