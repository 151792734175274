import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback
} from "react";
import { BoardProjectWrapper } from "./styles";
import { IRoleWorkflow, IWorkflow } from "../../../models/workflow";
import { IRoleBoard, IStage } from "../../../models/Stage";
import { ITaskCard } from "../../../models/Task";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  EStageType,
  ETaskStatus,
  EWorkflowProcess
} from "../../../models/common/models.enum";
import ErrorIcon from "@mui/icons-material/Error";
import UndoIcon from "@mui/icons-material/Undo";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ActionProject from "../ActionProject";
import AdvancedOptionsModal from "../AdvancedOptionsModal";
import CreateStageModal from "../CreateStageModal/create_stage_modal";
import ManageDragModal from "../ManageDragModal";
import UpdateTaskTransitionModal from "../UpdateTaskTransitionModal/UpdateTaskTransitionModal";
import { toggleMessage } from "../../../components/Toast/Toast";
import useAxios from "../../../components/UseAxios/useAxios";
import StageService from "../../../services/api/stage.service";
import Modal from "../../../components/Modal";
import { Box, Button, Divider, Typography } from "@mui/material";
import BasicMenu, { IMenuItem } from "../../../components/Menu/BasicMenu";
import { authTokens } from "../../../services/services";
import { IUser } from "../../../models/user";
import { TaskBoxWrapper } from "./components/TaskBox/styles";
import useNavigateSearch from "../../../components/NavigateSearch";
import dayjs from "dayjs";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import TaskService from "../../../services/api/task.service";
import Avatar from "../../../components/Avatar";
import AssignTaskModal from "../assignTaskModal/assign_task_modal";
import CreateTaskModal from "../CreateTaskModal/create_task_modal";
import { BorderLinearProgress } from "../../WorkflowManagement/components/WorkflowItem/border_linear_progress";

interface DragContextType {
  draggable: boolean;
  dragItem: UniqueIdentifier | null;
  forbiddenZones: UniqueIdentifier[];
  dragType: string | null;
  isDragging: boolean | null;
  dragStart: (
    e: React.DragEvent,
    dragItem: UniqueIdentifier | null,
    dragType: string
  ) => void;
  drag: (e: React.DragEvent, dragId: string, dragType: string) => void;
  dragEnd: (e: React.DragEvent) => void;
  drop: string | null;
  setDrop: (drop: string | null) => void;
  onDrop: (e: React.DragEvent) => void;
}

const DragContext = React.createContext<DragContextType | undefined>(undefined);

interface DragProps {
  draggable?: boolean;
  stages: IStage[];
  handleDrop: (data: {
    dragItem: UniqueIdentifier | null;
    dragType: string | null;
    drop: string | null;
  }) => void;
  children:
    | ReactNode
    | ((data: {
        activeItem: UniqueIdentifier | null;
        activeType: string | null;
        forbiddenZones: UniqueIdentifier[];
        isDragging: boolean | null;
      }) => ReactNode);
}

const Drag: FC<DragProps> = ({
  draggable = true,
  handleDrop,
  children,
  stages
}) => {
  const [dragType, setDragType] = useState<string | null>(null);
  const [dragItem, setDragItem] = useState<UniqueIdentifier | null>(null);
  const [forbiddenZones, setForbiddenZones] = useState<UniqueIdentifier[]>([]);
  const [isDragging, setIsDragging] = useState<boolean | null>(null);
  const [drop, setDrop] = useState<string | null>(null);

  useEffect(() => {
    document.body.style.cursor = dragItem ? "grabbing" : "default";
  }, [dragItem]);

  const dragStart = (
    e: React.DragEvent,
    dragItem: UniqueIdentifier | null,
    dragType: string
  ) => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = "move";
    setDragItem(dragItem);
    setDragType(dragType);
    if (dragType === "list") {
      const Ids = [...stages].reduce(function (
        a: UniqueIdentifier[],
        stage,
        _
      ) {
        if (stage.type !== EStageType.Step) a.push(stage.id);
        return a;
      }, []);

      setForbiddenZones(Ids);
    }
    if (dragType === "card") {
      const stageIndex = stages.findIndex(
        stage => stage.taskCards.findIndex(task => task.id === dragItem) >= 0
      );
      const Ids = [...stages]
        .filter((_, i, __) => i > stageIndex + 1 || i < stageIndex - 1)
        .map(e => e.id);

      setForbiddenZones(Ids);
    }
  };

  const drag = (e: React.DragEvent, dragId: string, dragType: string) => {
    e.stopPropagation();
    setIsDragging(true);
  };

  const dragEnd = (e: React.DragEvent) => {
    setDragItem(null);
    setForbiddenZones([]);
    setDragType(null);
    setIsDragging(false);
    setDrop(null);
  };

  const onDrop = (e: React.DragEvent) => {
    e.preventDefault();
    handleDrop({ dragItem, dragType, drop });
    setDragItem(null);
    setForbiddenZones([]);
    setDragType(null);
    setIsDragging(false);
    setDrop(null);
  };

  return (
    <DragContext.Provider
      value={{
        draggable,
        dragItem,
        dragType,
        forbiddenZones,
        isDragging,
        dragStart,
        drag,
        dragEnd,
        drop,
        setDrop,
        onDrop
      }}
    >
      {typeof children === "function"
        ? children({
            activeItem: dragItem,
            activeType: dragType,
            forbiddenZones: forbiddenZones,
            isDragging
          })
        : children}
    </DragContext.Provider>
  );
};

interface DragItemProps {
  as?: React.ElementType;
  dragItem: UniqueIdentifier | null;
  dragType: string;
  draggable?: boolean;
  [key: string]: any;
}

const DragDragItem = ({
  draggable = true,
  as,
  disable,
  dragItem,
  dragType,
  ...props
}: DragItemProps) => {
  const { dragStart, drag, dragEnd } = useContext(DragContext)!;

  const Component = as || "div";
  return (
    <Component
      onDragStart={(e: any) => dragStart(e, dragItem, dragType)}
      onDrag={drag}
      draggable={draggable}
      onDragEnd={dragEnd}
      {...props}
    />
  );
};

interface DropZoneProps {
  as?: React.ElementType;
  dropId?: any;
  dropType?: string;
  remember?: boolean;
  children?: ReactNode;
  disable?: boolean;
  style?: React.CSSProperties;
  [key: string]: any;
}

const DragDropZone = ({
  as,
  dropId,
  dropType,
  remember,
  children,
  disable,
  style,
  ...props
}: DropZoneProps) => {
  const { dragItem, dragType, setDrop, drop, onDrop } =
    useContext(DragContext)!;

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    return false;
  };

  const handleLeave = () => {
    if (!remember) {
      setDrop(null);
    }
  };

  const Component = as || "div";
  return (
    <Component
      disable={disable ? disable.toString() : undefined}
      onDragEnter={(e: any) => {
        dragItem && dropType === dragType && !disable && setDrop(dropId);
      }}
      onDragOver={handleDragOver}
      onDrop={(e: React.DragEvent) => {
        if (dropId !== undefined) onDrop(e);
      }}
      style={{ position: "relative", ...style }}
      {...props}
    >
      {children}
      {drop === dropId && (
        <div
          style={{
            position: "absolute",
            inset: "0px"
          }}
          onDragLeave={handleLeave}
        ></div>
      )}
    </Component>
  );
};

interface DropZonesProps {
  dropType: string;
  prevId: any;
  nextId: any;
  disable?: boolean;
  split?: "x" | "y";
  remember?: boolean;
  children: ReactNode;
  [key: string]: any;
}

const DragDropZones = ({
  dropType,
  prevId,
  nextId,
  split = "y",
  disable,
  remember,
  children,
  ...props
}: DropZonesProps) => {
  const { dragType, isDragging } = useContext(DragContext)!;

  return (
    <div
      style={{
        position: "relative"
      }}
      {...props}
    >
      {children}
      {dragType === dropType && isDragging && (
        <div
          style={{
            position: "absolute",
            inset: "0px",
            display: "flex",
            flexDirection: split === "x" ? "row" : "column"
          }}
        >
          <DragDropZone
            dropId={prevId}
            disable={disable}
            style={{
              width: "100%",
              height: "100%"
            }}
            dropType={dropType}
            remember={remember}
          />

          <DragDropZone
            dropId={nextId}
            disable={disable}
            style={{
              width: "100%",
              height: "100%"
            }}
            dropType={dropType}
            remember={remember}
          />
        </div>
      )}
    </div>
  );
};

interface DropGuideProps {
  as?: React.ElementType;
  dropId: any;
  dropType: string;
  [key: string]: any;
}

const DragDropGuide = ({ as, dropId, dropType, ...props }: DropGuideProps) => {
  const { drop, dragType } = useContext(DragContext)!;
  const Component = as || "div";

  return dragType === dropType && drop === dropId ? (
    <Component {...props} />
  ) : null;
};

interface CardProps {
  dragItem: boolean;
  canReassignTask?: boolean;
  onUpdateSuccess?: (newTask: ITaskCard) => void;
  onDeleteSuccess?: (newTask: ITaskCard) => void;
  onRollBackSuccess?: (newTask: ITaskCard) => void;
  task: ITaskCard;
  roleWorkflow?: IRoleBoard | null;
  roleStage?: IRoleWorkflow | null;
}

const Card: FC<CardProps> = ({
  task,
  dragItem,
  canReassignTask,
  onUpdateSuccess,
  onDeleteSuccess,
  onRollBackSuccess,
  roleWorkflow,
  roleStage
}) => {
  const navigateSearch = useNavigateSearch();
  const deleteTask = useAxios<string>({ loading: "OnRequest" });

  const rollbackTask = useAxios<string>({ loading: "OnRequest" });
  const [openUpdateAssign, setOpenUpdateAssign] = useState(false);
  const [openUpdateTask, setOpenUpdateTask] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const canAssignTask = useMemo(() => {
    return (
      roleStage?.isStageManagement ||
      roleWorkflow?.isProcessManagement ||
      roleWorkflow?.isCreator ||
      roleWorkflow?.isAssignTask ||
      canReassignTask
    );
  }, [roleStage, roleWorkflow]);

  const canManageTask = useMemo(() => {
    return (
      roleStage?.isStageManagement ||
      roleWorkflow?.isProcessManagement ||
      roleWorkflow?.isCreator ||
      roleWorkflow?.isAssignTask
    );
  }, [roleStage, roleWorkflow]);

  const MenuLabel = ({
    isGravity,
    onClick,
    name,
    icon
  }: {
    isGravity?: boolean;
    icon: ReactNode;
    name: string;
    onClick: () => void;
  }) => {
    return (
      <Box
        sx={{
          width: "100%",
          padding: "11px 12px",
          display: "flex",
          color: "#585656c9"
        }}
        onClick={onClick}
      >
        {icon}
        <Typography
          variant="caption"
          fontWeight={500}
          fontSize={13}
          color={isGravity ? "#aa0000" : "585656c9"}
        >
          {name}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (rollbackTask.isSuccess && rollbackTask.data) {
      onRollBackSuccess?.(task);
      toggleMessage({
        type: "success",
        message: rollbackTask.message ?? "Cập nhật thành công"
      });
    }
    if (rollbackTask.error) {
      toggleMessage({
        type: "error",
        message: rollbackTask.error.message ?? "Failed to rollback task"
      });
    }
  }, [rollbackTask.isSuccess, rollbackTask.error]);

  useEffect(() => {
    if (deleteTask.isSuccess) {
      toggleMessage({
        type: "success",
        message: deleteTask.message ?? ""
      });
      onDeleteSuccess?.(task);
      handleCloseDeleteDialog(); // Close the dialog after deletion
    }
    if (deleteTask.error) {
      toggleMessage({
        type: "error",
        message: deleteTask.error.message ?? ""
      });
    }
  }, [deleteTask.error, deleteTask.isSuccess]);

  const handleNewTab = () => {
    window.open(ROUTE_PATH.TASK + "?id=" + task.id, "_blank");
  };

  const handleShowDetail = useCallback(() => {
    navigateSearch(ROUTE_PATH.TASK, { id: task.id as string });
  }, []);
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true); // Open the delete confirmation dialog
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false); // Close the delete confirmation dialog
  };

  const handleConfirmDelete = () => {
    handleDelete(); // Call the delete function
  };

  const handleDelete = () => {
    deleteTask.request(TaskService.deleteTask(task.id));
  };

  const handleRollBack = () => {
    rollbackTask.request(TaskService.rollBack(task.id));
  };

  return (
    <>
      <TaskBoxWrapper
        className={` bg-white border border-gray-200 shadow-none p-3${dragItem ? " rotate-6" : ""}`}
        type={`${task.status}`}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignSelf={"stretch"}
          justifyContent={"space-between"}
        >
          <Typography
            className="font-bold text-md my-1 Overflow2Line prevent-select"
            sx={{ textDecoration: "underline" }}
            onClick={() => handleShowDetail()}
          >
            {task.title}
          </Typography>
          <BasicMenu
            onChange={() => {}}
            label={<MoreHorizIcon />}
            padding={0}
            options={[
              {
                value: "blank",
                label: MenuLabel({
                  onClick: handleNewTab,
                  icon: (
                    <OpenInBrowserIcon
                      sx={{ width: "20px", height: "20px", marginRight: 1 }}
                    />
                  ),
                  name: "Xem ở trong tab mới"
                })
              },
              ...(task.status === ETaskStatus.Failed
                ? [
                    {
                      value: "assign",
                      label: MenuLabel({
                        onClick: handleRollBack,
                        icon: (
                          <UndoIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Quay lại giai đoạn trước đó"
                      })
                    }
                  ]
                : []),
              ...(canManageTask && task.status === ETaskStatus.Inprogress
                ? [
                    {
                      value: "edit",
                      label: MenuLabel({
                        onClick: () => setOpenUpdateTask(true),
                        icon: (
                          <ModeEditOutlineOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Chỉnh sửa nhiệm vụ"
                      })
                    }
                  ]
                : []),

              ...(canAssignTask && task.status === ETaskStatus.Inprogress
                ? [
                    {
                      value: "assign",
                      label: MenuLabel({
                        onClick: () => setOpenUpdateAssign(true),
                        icon: (
                          <AddBoxRoundedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Giao lại cho người khác"
                      })
                    }
                  ]
                : []),
              ...(canManageTask
                ? [
                    {
                      value: "divider",
                      label: (
                        <Divider
                          sx={{
                            height: "1px",
                            width: "90%",
                            marginInline: 1
                          }}
                        />
                      )
                    },
                    {
                      value: "delete",
                      label: MenuLabel({
                        isGravity: true,
                        onClick: handleOpenDeleteDialog,
                        icon: (
                          <DeleteOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Xoá Nhiệm vụ"
                      })
                    }
                  ]
                : [])
            ]}
          />
        </Box>
        <div
          className="TaskShortDesc Overflow2Line"
          dangerouslySetInnerHTML={{
            __html: task.description
          }}
        />
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={0.5}
          marginTop={"auto"}
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            webkitLineClamp: "1",
            webkitBoxOrient: "vertical"
          }}
        >
          {/* <Tag name="backend" /> */}
        </Box>
        {!task.personAssigned && canAssignTask && (
          <Box
            display={"flex"}
            sx={{ width: "100%" }}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box className="TaskStatus" display={"flex"} flexDirection={"row"}>
              <ErrorIcon />
              <div> Chưa được giao</div>
            </Box>

            <Button
              sx={{
                minWidth: "32px",
                width: "52px",
                height: "28px",
                borderRadius: 1
              }}
              onClick={() => setOpenUpdateAssign(true)}
              variant="contained"
            >
              Giao
            </Button>
          </Box>
        )}
        {task.personAssigned && (
          <Box
            display={"flex"}
            sx={{ width: "100%", height: "28px" }}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} flexDirection={"row"} gap={0.5}>
              <Avatar
                src={task.personAssigned.avatar}
                alt="Avatar-Image"
                width={20}
                height={20}
              />
              <Typography
                variant="caption"
                fontSize={12}
                lineHeight={"unset"}
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  webkitLineClamp: "1",
                  webkitBoxOrient: "vertical"
                }}
              >
                {task.personAssigned.fullName}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              fontSize={12}
              className="TaskDeadline"
              lineHeight={"unset"}
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                webkitLineClamp: "1",
                webkitBoxOrient: "vertical"
              }}
            >
              {task.deadLine
                ? dayjs(task.deadLine).format("DD/MM/YYYY")
                : "No deadline"}
            </Typography>
          </Box>
        )}
      </TaskBoxWrapper>
      <AssignTaskModal
        open={openUpdateAssign}
        task={task}
        onUpdateSuccess={onUpdateSuccess ?? (() => {})}
        handleClose={() => setOpenUpdateAssign(false)}
      />
      <CreateTaskModal
        open={openUpdateTask}
        taskId={task.id}
        isUpdate
        workflowId={task.boardId}
        onCreateSuccess={newTaskcard => onUpdateSuccess?.(newTaskcard!)}
        handleClose={() => setOpenUpdateTask(false)}
      />
      <Modal
        title={"Xác nhận xóa nhiệm vụ"}
        textSubmit={"Xác nhận"}
        textClose="Huỷ bỏ"
        open={openDeleteDialog}
        onSubmit={handleConfirmDelete}
        disabledSubmit={deleteTask.isLoading}
        onClose={handleCloseDeleteDialog}
      >
        {"Bạn có chắc chắn muốn xóa nhiệm vụ này?"}
      </Modal>
    </>
  );
};

interface ListProps {
  name: string;
  dragItem: boolean;
  children: ReactNode;
  stage: IStage;
  roleWorkflow: IRoleBoard;
  isShowMenu?: boolean;
  onLeftAdd?: () => void;
  onRightAdd?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
}

const List = ({
  name,
  dragItem,
  children,
  stage,
  roleWorkflow,
  isShowMenu,
  onLeftAdd,
  onRightAdd,
  onDelete,
  onUpdate
}: ListProps) => {
  const hasPermission =
    roleWorkflow.isCreator ||
    roleWorkflow.isProcessManagement ||
    stage.roleWorkflowByUser?.isStageManagement;

  const itemsPadding = { width: "100%", padding: "11px 12px" };

  const menuItems: IMenuItem[] = [
    {
      value: "edit",
      label: (
        <Box sx={itemsPadding} onClick={onUpdate}>
          Chỉnh sửa giai đoạn
        </Box>
      )
    },
    // {
    //   value: "edit-guideline",
    //   label: (
    //     <Box sx={itemsPadding}>
    //       Hướng dẫn hoàn thành các nhiệm vụ trong giai đoạn
    //     </Box>
    //   )
    // },
    // {
    //   value: "edit-task",
    //   label: (
    //     <Box sx={itemsPadding}>
    //       Tuỳ chỉnh các công việc tạo sẵn trong giai đoạn
    //     </Box>
    //   )
    // },
    // {
    //   value: "edit-drag",
    //   label: (
    //     <Box sx={itemsPadding} onClick={props.onManageDrag}>
    //       Quản lý tuỳ chọn kéo ngược
    //     </Box>
    //   )
    // },
    // {
    //   value: "advanced",
    //   label: (
    //     <Box sx={itemsPadding} onClick={props.onAdvancedOptions}>
    //       Tuỳ chọn nâng cao
    //     </Box>
    //   )
    // },
    {
      value: "delete",
      label: (
        <Box sx={{ ...itemsPadding, color: "#ff4e4e" }} onClick={onDelete}>
          Xoá giai đoạn
        </Box>
      )
    },
    {
      value: "divider",
      label: (
        <Divider
          sx={{
            height: "1px",
            width: "90%",
            marginInline: 1
          }}
        />
      )
    },
    {
      value: "left_add",
      label: (
        <Box sx={itemsPadding} onClick={onLeftAdd}>
          Thêm 1 giai đoạn bên trái
        </Box>
      )
    },
    {
      value: "right_add",
      label: (
        <Box sx={itemsPadding} onClick={onRightAdd}>
          Thêm 1 giai đoạn bên phải
        </Box>
      )
    }
  ];

  return (
    <div
      className={` ${stage.type === EStageType.Complete ? "bg-green-400" : stage.type === EStageType.Cancel ? "bg-red-400" : "bg-gray-100"} mb-2 w-72 shrink-0 grow-0 shadow${dragItem ? " rotate-9" : ""} `}
    >
      <div
        className={`border-gray-200 py-2 ${stage.type === EStageType.Complete ? "bg-green-400" : stage.type === EStageType.Cancel ? "bg-red-400" : "bg-gray-100"}`}
      >
        <Box
          className="px-3 py-0 flex flex-row justify-between align-center text-wrap"
          sx={{
            "& #basic-button": {
              "& .menu-button": {
                padding: 0
              }
            }
          }}
        >
          <h5 className="truncate font-bold text-lg my-1 prevent-select flex-shrink">
            {name}
          </h5>
          {isShowMenu && hasPermission && (
            <div className="flex-0">
              <BasicMenu
                key={name}
                onChange={() => {}}
                label={<ArrowDropDownIcon sx={{ padding: 0 }} />}
                options={menuItems}
                padding={0}
              />
            </div>
          )}
          {!isShowMenu && <Box height={24} />}
        </Box>
        <Box className="px-3">
          <BorderLinearProgress
            variant="determinate"
            value={0}
            height={4}
            barcolor="#308fe8"
            background="#dbdbdb"
          />
          <Box
            display={"flex"}
            flexDirection={"row"}
            color={"#8e8e8e"}
            fontSize={10}
          >
            <p
              className={`text-xs mt-2 ${stage.type !== EStageType.Step && "text-white"} prevent-select`}
            >
              {stage.taskCards.length} nhiệm vụ
            </p>
            {/* <Box sx={{ margin: "0 2px" }}> {"-"} </Box>
        <p>0 Q.HAN</p> */}
          </Box>
        </Box>
      </div>
      {children}
    </div>
  );
};

interface Props {
  workflow: IWorkflow;
  role: IRoleBoard;
  stages: IStage[];
  setStages: (newStages: IStage[]) => void;
  onReload: () => void;
  onCloseWorkflow: (id: string) => void;
  onUpdateStagePosition: (currentPosition: IStage, newPosition: IStage) => void;
  onUpdateTaskPosition: (
    currentTask: ITaskCard,
    newPosition: IStage,
    index: number
  ) => void;
  filter: ReactNode;
}
interface PositionCreateState {
  stageSelected?: IStage;
  isUpdate?: boolean;
  side: number;
}

const BoardProject1: FC<Props> = ({
  workflow,
  stages,
  setStages,
  role,
  onReload,
  onCloseWorkflow,
  onUpdateStagePosition,
  onUpdateTaskPosition,
  filter
}) => {
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [openCreateStage, setOpenCreateStage] = useState(false);
  const [openUpdateStage, setOpenUpdateStage] = useState(false);
  const deleteStage = useAxios<string>({ loading: "OnRequest" });
  const [positionSelectedStage, setPositionCreateStage] =
    useState<PositionCreateState>({ side: 0 });
  const [openManageDragStage, setOpenManageDragStage] = useState(false);
  const [openAdvancedOptions, setOpenAdvancedOptions] = useState(false);

  const [deleteStageDialog, setDeleteStageDialog] = useState<{
    open: boolean;
    stage: IStage | null;
  }>({ open: false, stage: null });

  const [updateTaskTransition, setUpdateTaskTransition] = useState<{
    open: boolean;
    task: ITaskCard;
    stage: IStage;
    newStages: IStage[];
    index: number;
  } | null>();

  const stageCached = useMemo(() => {
    if (!updateTaskTransition) return stages;
  }, [updateTaskTransition]);

  const hasStepStage = useMemo(
    () => stages.find(e => e.type === EStageType.Step),
    [stages]
  );

  useEffect(() => {
    authTokens.getUser().then(user => setUserInfo(user));
  }, []);

  const handleUpdateTaskPosition = async (
    newStages: IStage[],
    activeTask: ITaskCard,
    newStage: IStage,
    index: number
  ) => {
    if (
      newStage.id !== activeTask.workflowId &&
      newStage.reassignedWork === EWorkflowProcess.TaskAssigneeDecides
    ) {
      setUpdateTaskTransition({
        open: true,
        task: activeTask,
        stage: newStage,
        newStages: newStages,
        index: index
      });
      return;
    }
    setStages(newStages);
    onUpdateTaskPosition(activeTask, newStage, index);
  };

  const handleAdd = () => {
    setPositionCreateStage({ side: 1 });
    setOpenCreateStage(true);
  };

  const handleLeftAdd = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 1 });
    setOpenCreateStage(true);
  };

  const handleRightAdd = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 2 });
    setOpenCreateStage(true);
  };

  const handleAddSuccess = () => {
    onReload();
  };

  const handleUpdateWorkflowSuccess = () => {
    onReload();
  };

  const handleCopyWorkflowSuccess = () => {
    onReload();
  };

  const handleUpdateStage = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 0, isUpdate: true });
    setOpenUpdateStage(true);
  };

  const handleDeleteStage = async (stage: IStage) => {
    handleOpenDeleteStageDialog(stage);
  };

  const handleOpenDeleteStageDialog = (stage: IStage) => {
    setDeleteStageDialog({ open: true, stage: stage }); // Open the delete confirmation dialog
  };

  const handleCloseDeleteStageDialog = () => {
    setDeleteStageDialog({ open: false, stage: null }); // Close the delete confirmation dialog
  };

  const handleUpdateTaskcard = (newTask: ITaskCard) => {
    const stageIndex = stages.findIndex(e => e.id === newTask.workflowId);
    const taskIndex = stages[stageIndex].taskCards.findIndex(
      e => e.id === newTask.id
    );
    const newStages = structuredClone(stages);
    newStages[stageIndex].taskCards[taskIndex] = newTask;
    setStages(newStages);
  };

  const handleDeletedTaskcard = (newTask: ITaskCard) => {
    const stageIndex = stages.findIndex(e => e.id === newTask.workflowId);
    const taskIndex = stages[stageIndex].taskCards.findIndex(
      e => e.id === newTask.id
    );
    const newStages = structuredClone(stages);
    newStages[stageIndex].taskCards.splice(taskIndex, 1);
    setStages(newStages);
  };

  const handleRollbackTaskcard = () => {
    onReload();
  };

  const handleConfirmDelete = () => {
    deleteStage.request(
      StageService.deleteStage(deleteStageDialog.stage!.id as string)
    );
  };

  useEffect(() => {
    if (deleteStage.isSuccess) {
      onReload();
      handleCloseDeleteStageDialog(); // Close the dialog after deletion
    }
    if (deleteStage.error) {
      toggleMessage({
        type: "error",
        message: deleteStage.error.message ?? ""
      });
    }
  }, [deleteStage.isSuccess, deleteStage.error]);

  const handleDrop = ({
    dragItem,
    dragType,
    drop
  }: {
    dragItem: UniqueIdentifier | null;
    dragType: string | null;
    drop: any;
  }) => {
    if (dragType === "card" && drop !== undefined && drop !== null) {
      let [newListPosition, newCardPosition] = drop!
        .toString()
        .split("|")
        .map((string: any) => parseInt(string as string));

      let newStages = structuredClone(stages);
      let oldCardPosition: number;
      let oldListPosition = stages.findIndex(stage => {
        oldCardPosition = stage.taskCards.findIndex(
          card => card.id === dragItem
        );
        return oldCardPosition >= 0;
      });

      let card = stages[oldListPosition].taskCards[oldCardPosition!];

      if (
        newListPosition === oldListPosition &&
        oldCardPosition! < newCardPosition
      ) {
        newCardPosition--;
      }

      newStages[oldListPosition].taskCards.splice(oldCardPosition!, 1);
      newStages[newListPosition].taskCards.splice(newCardPosition, 0, card);

      if (
        JSON.stringify(stages[newListPosition].taskCards) ===
        JSON.stringify(newStages[newListPosition].taskCards)
      )
        return;

      handleUpdateTaskPosition(
        newStages,
        newStages[newListPosition].taskCards[newCardPosition],
        newStages[newListPosition],
        newCardPosition
      );
    } else if (dragType === "list" && drop !== undefined && drop !== null) {
      let newListPosition = drop!;
      let oldListPosition = stages.findIndex(stage => stage.id === dragItem);

      let newStages = structuredClone(stages);
      let stage = stages[oldListPosition];
      if (oldListPosition < newListPosition) {
        newListPosition--;
      }
      newStages.splice(oldListPosition, 1);
      newStages.splice(newListPosition, 0, stage);

      if (JSON.stringify(stages) === JSON.stringify(newStages)) return;

      setStages(newStages);
      onUpdateStagePosition(
        newStages[newListPosition],
        newStages[oldListPosition]
      );
    }
  };

  return (
    <>
      <BoardProjectWrapper className="flex flex-col overflow-hidden">
        {filter}

        <Drag handleDrop={handleDrop} stages={stages}>
          {({ activeItem, activeType, isDragging, forbiddenZones }) => (
            <DragDropZone className="flex overflow-auto h-full w-full ">
              {stages.map((stage, stagePosition) => {
                return (
                  <React.Fragment key={stage.id}>
                    <DragDropZone
                      dropId={stagePosition}
                      dropType="list"
                      remember={true}
                    >
                      <DragDropGuide
                        dropId={stagePosition}
                        dropType="list"
                        className=" bg-red-200 h-96 w-72 shrink-0 grow-0"
                      />
                    </DragDropZone>
                    <DragDropZones
                      className="flex flex-col h-full"
                      prevId={stagePosition}
                      nextId={stagePosition + 1}
                      dropType="list"
                      split="x"
                      disable={forbiddenZones.includes(stage.id)}
                      remember={true}
                    >
                      <DragDragItem
                        dragItem={stage.id}
                        className={`cursor-pointer ${activeItem === stage.id && activeType === "list" && isDragging ? "hidden" : "translate-x-0"}`}
                        dragType="list"
                        draggable={
                          stage.type === EStageType.Step &&
                          !(
                            role.isCreator === false &&
                            role.isProcessManagement === false
                          )
                        }
                      >
                        <List
                          key={"stage" + stage.id}
                          name={stage.name}
                          dragItem={
                            activeItem === stage.id && activeType === "list"
                          }
                          stage={stage}
                          roleWorkflow={role}
                          isShowMenu={stage.type === EStageType.Step}
                          onLeftAdd={() => handleLeftAdd(stage)}
                          onRightAdd={() => handleRightAdd(stage)}
                          onDelete={() => handleDeleteStage(stage)}
                          onUpdate={() => handleUpdateStage(stage)}
                        >
                          {stages[stagePosition].taskCards.map(
                            (card, cardPosition) => {
                              return (
                                <DragDropZones
                                  key={card.id}
                                  prevId={`${stagePosition}|${cardPosition}`}
                                  nextId={`${stagePosition}|${cardPosition + 1}`}
                                  dropType="card"
                                  disable={forbiddenZones.includes(stage.id)}
                                  remember={true}
                                >
                                  <DragDropGuide
                                    dropId={`${stagePosition}|${cardPosition}`}
                                    className=" bg-gray-200 h-24 "
                                    dropType="card"
                                  />
                                  <DragDragItem
                                    dragItem={card.id}
                                    className={`cursor-pointer ${activeItem === card.id && activeType === "card" && isDragging ? "hidden" : "translate-x-0"}`}
                                    dragType="card"
                                    draggable={
                                      !(
                                        !role.isCreator &&
                                        !role.isProcessManagement &&
                                        userInfo?.id !== card.personAssignedId
                                      )
                                    }
                                  >
                                    <Card
                                      dragItem={
                                        activeItem === card.id &&
                                        activeType === "card"
                                      }
                                      roleStage={stage.roleWorkflowByUser}
                                      roleWorkflow={role}
                                      onUpdateSuccess={newTaskcard =>
                                        handleUpdateTaskcard(newTaskcard)
                                      }
                                      onDeleteSuccess={newTaskcard =>
                                        handleDeletedTaskcard(newTaskcard)
                                      }
                                      onRollBackSuccess={newTaskcard =>
                                        handleRollbackTaskcard()
                                      }
                                      task={card}
                                      canReassignTask={
                                        stage.reassignedWork ===
                                          EWorkflowProcess.TaskAssigneeDecides &&
                                        userInfo?.id === card.personAssignedId
                                      }
                                    />
                                  </DragDragItem>
                                </DragDropZones>
                              );
                            }
                          )}
                          <DragDropZone
                            dropId={`${stagePosition}|${stages[stagePosition].taskCards.length}`}
                            dropType="card"
                            disable={forbiddenZones.includes(stage.id)}
                            remember={true}
                          >
                            <DragDropGuide
                              dropId={`${stagePosition}|${stages[stagePosition].taskCards.length}`}
                              className=" bg-gray-200 h-24"
                              dropType="card"
                            />
                          </DragDropZone>
                        </List>
                      </DragDragItem>
                      <DragDropZone
                        dropId={`${stagePosition}|${stages[stagePosition].taskCards.length}`}
                        className="grow"
                        dropType="card"
                        disable={forbiddenZones.includes(stage.id)}
                        remember={true}
                      />
                    </DragDropZones>
                  </React.Fragment>
                );
              })}
              <DragDropZone
                dropId={stages.length}
                dropType="list"
                remember={true}
              >
                <DragDropGuide
                  dropId={stages.length}
                  dropType="list"
                  className=" bg-gray-200 h-96 w-72 shrink-0 grow-0"
                />
              </DragDropZone>
            </DragDropZone>
          )}
        </Drag>
        <CreateStageModal
          open={openCreateStage || openUpdateStage}
          workflow={workflow}
          isUpdate={openUpdateStage}
          {...positionSelectedStage}
          handleClose={() =>
            openCreateStage
              ? setOpenCreateStage(false)
              : setOpenUpdateStage(false)
          }
          handleSuccess={handleAddSuccess}
        />
        <ManageDragModal
          open={openManageDragStage}
          handleClose={() => setOpenManageDragStage(false)}
        />
        <AdvancedOptionsModal
          open={openAdvancedOptions}
          handleClose={() => setOpenAdvancedOptions(false)}
        />
        <Modal
          title={"Xác nhận xóa giai đoạn"}
          textSubmit={"Xác nhận"}
          textClose="Huỷ bỏ"
          open={deleteStageDialog.open}
          onSubmit={handleConfirmDelete}
          disabledSubmit={deleteStage.isLoading}
          onClose={handleCloseDeleteStageDialog}
        >
          {"Bạn có chắc chắn muốn xóa giai đoạn này?"}
        </Modal>
        {updateTaskTransition && (
          <UpdateTaskTransitionModal
            open={updateTaskTransition.open}
            workflowId={updateTaskTransition.stage.id}
            newStages={updateTaskTransition.newStages}
            task={updateTaskTransition.task}
            onUpdateSuccess={() => {
              setStages(updateTaskTransition.newStages);
              onUpdateTaskPosition(
                updateTaskTransition.task,
                updateTaskTransition.stage,
                updateTaskTransition.index
              );
              setTimeout(() => {
                setUpdateTaskTransition(null);
              }, 600);
            }}
            handleClose={() => {
              setStages(stageCached ?? stages);
              setUpdateTaskTransition(null);
            }}
          />
        )}
      </BoardProjectWrapper>
      <ActionProject
        workflow={workflow}
        role={role}
        hasStepStage={!!hasStepStage}
        onCreateTaskSuccess={() => onReload()}
        onCreateStage={() => handleAdd()}
        onCloseWorkflow={() => onCloseWorkflow(workflow.id!)}
        onUpdateWorkflowSuccess={() => handleUpdateWorkflowSuccess()}
        onCopyWorkflowSuccess={() => handleCopyWorkflowSuccess()}
      />
    </>
  );
};
export default BoardProject1;
