export const ROUTE_PATH = {
  //not authorization
  NOTFOUND: "404",
  LOGIN: "/login",

  INDEX: "/",
  ABOUTUS: "/about-us",
  SERVICE: "/service",
  PRICING: "/pricing",
  PROJECT: "/project",
  FREE_TOOLS: "/free-tools",
  WEB_AUDIT: "/web-audit",
  WEB_COMPETITIVE: "/web-competitive",
  PAGE_AUDIT: "/page-audit",
  PAGE_COMPETITIVE: "/page-competitive",
  OPTIMIZE_HEADER: "/optimize-header",
  KEYWORD_DENSITY: "/keyword-density",
  POTENTIAL_KEYWORD: "/potential-keyword",
  KEYWORD_GROUP: "/keyword-group",
  COMPRESS_PHOTO: "/compress-photo",
  OUTLINE: "/outline",
  MATERIAL: "/material",
  WORKFLOWS: "/workflows",
  WORKFLOWS_CLOSED: "/workflows/closed",
  WORKFLOWS_MINE: "/workflows/mine",
  PROJECTS: "/workflows/projects",
  PROJECTS_MANAGERIAL: "/projects/managerial",
  ACCOUNT: "/account",
  ACCOUNT_INFO: "/account/info",
  ACCOUNT_MEMBER: "/account/member",
  ACCOUNT_DEPARTMENT: "/account/department",
  ACCOUNT_NOTI: "/account/notification",
  MANAGER: "/projects/manager",
  MANAGER_DETAIL: "/projects/manager/detail",
  TASK: "/workflows/projects/task",
  RESOURCE: "/resource",
  APPROVE: "/approve",
  BOOKING: "/booking",
  TIMEOFF: "/time-off"
};
