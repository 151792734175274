import { Autocomplete, Chip, TextField } from "@mui/material";
import { Control, Controller, UseFormTrigger } from "react-hook-form";
import { safeRead } from "../../../common/handles/common.handles";
import { ReactNode, SyntheticEvent } from "react";
import { ITag } from "../../../models/user";

interface Props<T> {
  name: string;
  control?: Control<any, any>;
  errors: any;
  isError: boolean;
  inputValue: string;
  multiple?: boolean;
  limit?: number;
  onInputChanged?: (e: SyntheticEvent, v: string) => void;
  id?: string | undefined;
  defaultValue?: T[];
  options: T[];
  optionDisabled?: T[];
  noOptionsText?: ReactNode;
  isOptionEqualToValue?: ((option: T, value: T) => boolean) | undefined;
  getOptionLabel?: (option: T) => string;
  renderOption?: (option: T) => ReactNode;
  getOptionTag?: (option: T) => string;
  filterSelectedOptions?: boolean | undefined;
  disableClearable?: boolean | undefined;
  disableCloseOnSelect?: boolean | undefined;
  filterOptions?: ((option: T) => string) | undefined;
  open?: boolean;
  size: "small" | "medium" | "large";
  placeholder: string | undefined;
  popupIcon?: React.ReactNode;
  loading?: boolean;
  trigger?: UseFormTrigger<any>;
}

export const AutocompleteForm = <T,>({
  name,
  control,
  errors,
  isError,
  id,
  defaultValue,
  inputValue,
  onInputChanged,
  multiple,
  limit,
  options,
  open,
  noOptionsText,
  isOptionEqualToValue,
  getOptionLabel,
  renderOption,
  getOptionTag,
  disableCloseOnSelect,
  size,
  placeholder,
  popupIcon,
  optionDisabled,
  loading,
  trigger
}: Props<T>) => {
  const error: { message: string; type: any; ref: any } = safeRead(
    errors,
    name
  );

  const check = (option: T): boolean => {
    const value = option as ITag;
    const list = optionDisabled as ITag[];
    if (list.find(o => o.id === value.id)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          limitTags={2}
          multiple={multiple}
          id={id}
          inputValue={inputValue}
          onInputChange={(e, v) => {
            if (multiple && limit && value?.length >= limit) return;
            if (!multiple) return onChange(undefined);
            onInputChanged?.(e, v);
          }}
          value={value}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_, newValue) => {
            trigger?.(name);
            onChange(newValue);
          }}
          open={open}
          noOptionsText={noOptionsText}
          options={options}
          loading={loading}
          getOptionLabel={getOptionLabel}
          filterSelectedOptions
          disableClearable
          disableCloseOnSelect={disableCloseOnSelect}
          popupIcon={popupIcon}
          forcePopupIcon={!!popupIcon}
          renderOption={
            renderOption &&
            ((props, option: T) => (
              <li {...props} key={props.key}>
                {renderOption(option)}
              </li>
            ))
          }
          renderTags={
            getOptionTag &&
            ((value: readonly any[], getTagProps) =>
              value.map((option: any, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    label={getOptionTag(option)}
                    {...tagProps}
                    size="small"
                    sx={{
                      backgroundColor: "#54bd9566",
                      fontWeight: "500",
                      margin: "0px !important"
                    }}
                  />
                );
              }))
          }
          renderInput={params => (
            <TextField
              {...params}
              size={size}
              sx={{
                "& .MuiInputBase-sizeSmall": {
                  paddingTop: "8.5px !important",
                  paddingBottom: "8.5px !important",
                  paddingLeft: "8px !important"
                }
              }}
              helperText={error?.message}
              placeholder={value?.length === 0 ? placeholder : undefined}
              error={isError}
            />
          )}
          getOptionDisabled={
            optionDisabled == undefined ? undefined : option => check(option)
          }
        />
      )}
    />
  );
};
