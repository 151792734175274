import { envConfig } from "../../config/env.config";
import { IDownloadFile } from "../../models/Comment";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import {
  ICreateTimeOffRequest,
  ITimeOff,
  ITimeOffPagingRequest,
  ITimeOffRegistedPagingRequest,
  ITimeOffTypeResponse,
  IUpdateMailCCRequest,
  IUpdateTimeOffStatusRequest,
  IUpdateTotalTimeOffReq
} from "../../models/timeoff";
import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API_TIMEOFF = ApiEndpoints.TIMEOFF;
const baseURL = envConfig.API_ENDPOINT;

const createTimeOff = (
  params: ICreateTimeOffRequest
): Promise<IBaseResponseModel<string>> => {
  return api.post(baseURL, API_TIMEOFF.CREATE.url, params);
};
const deleteTimeOff = (id: string): Promise<IBaseResponseModel<string>> => {
  return api.delete(baseURL, API_TIMEOFF.DELETE.url + `/${id}`);
};

const getTimeOffType = (
  Operation: number
): Promise<IBaseResponseModel<ITimeOffTypeResponse[]>> => {
  return api.get(baseURL, API_TIMEOFF.GET_TIMEOFFTYPE.url, {
    params: { Operation: Operation }
  });
};

const getMyTimeOffs = (
  params: ITimeOffPagingRequest
): Promise<IBasePagingRes<ITimeOff>> => {
  return api.get(baseURL, API_TIMEOFF.GET_MINE.url, { params: params });
};

const getManagerTimeOffs = (
  params: ITimeOffPagingRequest
): Promise<IBasePagingRes<ITimeOff>> => {
  return api.get(baseURL, API_TIMEOFF.GET_MANAGER_TIMEOFF.url, {
    params: params
  });
};
const getCEOTimeOffs = (
  params: ITimeOffPagingRequest
): Promise<IBasePagingRes<ITimeOff>> => {
  return api.get(baseURL, API_TIMEOFF.GET_CEO_TIMEOFF.url, { params: params });
};

const managerUpdateTimeOffStatus = (
  params: IUpdateTimeOffStatusRequest
): Promise<IBaseResponseModel<string>> => {
  return api.put(
    baseURL,
    API_TIMEOFF.MANAGER_UPDATE_TIMEOFF_STATUS.url,
    params
  );
};
const CEOUpdateTimeOffStatus = (
  params: IUpdateTimeOffStatusRequest
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_TIMEOFF.CEO_UPDATE_TIMEOFF_STATUS.url, params);
};

const getMailCCDefault = (): Promise<IBaseResponseModel<string>> => {
  return api.get(baseURL, API_TIMEOFF.GET_MAIL_CC.url);
};

const updateMailCCDefault = (
  params: IUpdateMailCCRequest
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_TIMEOFF.UPDATE_MAIL_CC.url, params.data);
};
const viewUserRegisterTimeOff = (
  params: ITimeOffRegistedPagingRequest
): Promise<IBaseResponseModel<string>> => {
  return api.get(baseURL, API_TIMEOFF.GET_PAGE_VIEW_USER_REGISTER_TIMEOFF.url, {
    params: params
  });
};
const exportExcelTimoff = (
  params: ITimeOffRegistedPagingRequest
): Promise<IBaseResponseModel<IDownloadFile>> => {
  return api.get(baseURL, API_TIMEOFF.EXPORT_EXCEL_TIMEOFF.url, {
    params: params
  });
};

const updateTotalTimeOffForUser = (
  params: IUpdateTotalTimeOffReq
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API_TIMEOFF.UPDATE_TOTAL_TIMEOFF.url, params);
};

const TimeOffService = {
  getTimeOffType,
  createTimeOff,
  deleteTimeOff,
  getMyTimeOffs,
  getManagerTimeOffs,
  getCEOTimeOffs,
  managerUpdateTimeOffStatus,
  CEOUpdateTimeOffStatus,
  updateMailCCDefault,
  viewUserRegisterTimeOff,
  getMailCCDefault,
  exportExcelTimoff,
  updateTotalTimeOffForUser
};

export default TimeOffService;
