import {
  Box,
  Button,
  Chip,
  Divider,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonHandle from "../../../../common/handles/common.handles";
import { TIME_FORMAT } from "../../../../config/constants";
import {
  EMeetingRoomStatus,
  meetingRoomStatusColor,
  meetingRoomStatusTitle
} from "../../../../models/common/models.enum";
import { Wrapper } from "./styles";

import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { IMeetingSchedule } from "../../../../models/meeting";

import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { useApi } from "../../../../hooks/useApi.hook";
import MeetingService from "../../../../services/api/meeting.service";
import { toggleMessage } from "../../../../components/Toast/Toast";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { toggleConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import moment from "moment";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";

type Props = {
  item?: IMeetingSchedule;
  isAdminUpdate: boolean;
  isUserUpdate: boolean;
  isUpdate: boolean;
  isRegister: boolean;
  reFetch: Function;
};

type RowProps = {
  title: string;
  icon: React.ReactNode;
  content?: string;
  contentBox?: React.ReactNode;
};

const EventDetailPopover = ({
  item,
  reFetch,
  isAdminUpdate,
  isUserUpdate,
  isUpdate,
  isRegister
}: Props) => {
  const [isCancel, setIsCancel] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const updateSchedule = useApi<IMeetingSchedule>({});
  const deleteSchedule = useApi<IMeetingSchedule>({});

  const [actionLeft, setActionLeft] = useState<{
    text: string;
    color?:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning";
    variant?: "text" | "contained" | "outlined";
    onClick: Function;
  } | null>(null);
  const [actionRight, setActionRight] = useState<{
    text: string;
    color?:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning";
    variant?: "text" | "contained" | "outlined";
    onClick: Function;
  } | null>(null);

  const RowLayout = (props: RowProps) => {
    return (
      <Box display="flex" margin="4px 0">
        {props.icon}
        <Box display="flex" flexDirection="column" marginLeft="4px">
          <Box display="flex" marginBottom="4px">
            <Typography variant="caption" sx={{ color: "GrayText" }}>
              {props.title}
            </Typography>
          </Box>
          {props.contentBox ? (
            props.contentBox
          ) : (
            <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
              {props.content}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };
  const adminUpdateStatus = useApi<IMeetingSchedule>({});
  const userUpdateStatus = useApi<IMeetingSchedule>({});

  function handleAdminUpdateStatus(status: EMeetingRoomStatus) {
    adminUpdateStatus.request(
      MeetingService.adminUpdateStatus({
        id: item?.id ?? "",
        status: status
      }).then(res => {
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Cập nhật thành công"
          });
          reFetch();
        } else if (!res.success) {
          toggleMessage({
            type: "error",
            message: res?.message ?? "Đã xảy ra lỗi"
          });
        }
      })
    );
  }

  function handleUserUpdateStatus(status: EMeetingRoomStatus) {
    // console.log("first", {
    //   id: item?.id ?? "",
    //   status: status,
    //   ReasonForFancellation: inputValue
    // });
    // if (status === EMeetingRoomStatus.WaitCancel) {
    //   console.log("why", inputValue);
    // }

    userUpdateStatus.request(
      MeetingService.userUpdateStatus({
        id: item?.id ?? "",
        status: status,
        ReasonForFancellation: inputValue
      }).then(res => {
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Cập nhật thành công"
          });
          reFetch();
        } else if (!res.success) {
          toggleMessage({
            type: "error",
            message: res?.message ?? "Đã xảy ra lỗi"
          });
        }
      })
    );
  }

  useEffect(() => {
    switch (item?.status) {
      case EMeetingRoomStatus.Pending:
        setActionRight(
          isAdminUpdate
            ? {
                text: "Duyệt",
                variant: "contained",
                onClick: () => {
                  toggleConfirmModal({
                    title: `Bạn muốn phê duyệt đăng ký này?`,
                    content: `Bạn chắc chắn muốn phê duyệt đăng ký này?`,
                    onSubmit: async () =>
                      handleAdminUpdateStatus(EMeetingRoomStatus.Confirmed),
                    submitText: "Xác nhận",
                    type: "confirm"
                  });
                }
              }
            : null
        );
        setActionLeft(
          isAdminUpdate
            ? {
                text: "Từ chối",
                color: "error",
                variant: "outlined",
                onClick: () => {
                  toggleConfirmModal({
                    title: `Bạn từ chối đăng ký này?`,
                    content: (
                      <Box>
                        <p>Bạn muốn từ chối đăng ký này?</p>
                        <p>Đăng ký này sẽ bị xoá sau khi xác nhận</p>
                      </Box>
                    ),
                    onSubmit: async () => {
                      deleteSchedule.request(
                        MeetingService.deleteMeeting(item.id).then(response => {
                          if (response.success) {
                            toggleMessage({
                              type: "success",
                              message: "Đăng ký đã bị xoá"
                            });
                          }
                        })
                      );
                    },
                    submitText: "Xác nhận",
                    type: "warning-red"
                  });
                }
              }
            : null
        );
        break;

      case EMeetingRoomStatus.Confirmed:
        const actionComplete: any =
          isAdminUpdate || isUserUpdate
            ? {
                text: "Kết thúc",
                variant: "contained",
                onClick: () => {
                  toggleConfirmModal({
                    title: `Bạn muốn kết thúc cuộc họp này?`,
                    content: `Bạn sẽ không thể tiếp tục sử dụng phòng họp sau khi xác nhận`,
                    onSubmit: async () => {
                      if (isAdminUpdate) {
                        handleAdminUpdateStatus(EMeetingRoomStatus.Completed);
                      } else if (isUserUpdate) {
                        handleUserUpdateStatus(EMeetingRoomStatus.Completed);
                      }
                    },
                    submitText: "Xác nhận",
                    type: "warning-red"
                  });
                }
              }
            : null;
        const actionCancel: any = isUserUpdate
          ? {
              text: "Huỷ đăng ký",
              color: "warning",
              variant: "outlined",
              onClick: () => {
                // handleUserUpdateStatus(EMeetingRoomStatus.WaitCancel);
                setIsCancel(true);
                setActionLeft({
                  text: "Huỷ bỏ",
                  variant: "outlined",
                  color: "error",
                  onClick: () => {
                    // handleUserUpdateStatus(EMeetingRoomStatus.Completed);
                    setIsCancel(false);
                    setActionRight(actionComplete);
                    setActionLeft(actionCancel);
                  }
                });
                setActionRight({
                  text: "Xác nhận huỷ",
                  variant: "contained",
                  // color: "error",
                  onClick: () => {
                    toggleConfirmModal({
                      title: `Bạn muốn yêu cầu huỷ?`,
                      content: `Bạn chắc chắn muốn yêu cầu huỷ đăng ký này?`,
                      onSubmit: async () => {
                        if (isAdminUpdate) {
                          handleAdminUpdateStatus(
                            EMeetingRoomStatus.WaitCancel
                          );
                        } else if (isUserUpdate) {
                          handleUserUpdateStatus(EMeetingRoomStatus.WaitCancel);
                        }
                      },
                      submitText: "Xác nhận",
                      type: "warning"
                    });
                  }
                });
              }
            }
          : null;
        setActionRight(actionComplete);
        setActionLeft(actionCancel);
        break;

      case EMeetingRoomStatus.WaitCancel:
        setActionRight(
          isAdminUpdate
            ? {
                text: "Xác nhận",
                variant: "contained",
                onClick: () => {
                  toggleConfirmModal({
                    title: `Bạn muốn phê duyệt yêu cầu huỷ?`,
                    content: `Bạn chắc chắn muốn phê duyệt yêu cầu huỷ đăng ký này?`,
                    onSubmit: async () =>
                      handleAdminUpdateStatus(EMeetingRoomStatus.Cancel),
                    submitText: "Xác nhận",
                    type: "warning"
                  });
                }
              }
            : null
        );
        break;

      default:
        break;
    }
  }, []);

  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="6px 14px"
      >
        <Box>
          <Typography
            variant="subtitle2"
            color={meetingRoomStatusColor(item?.status).color}
          >
            {item?.title}
          </Typography>
          <p style={{ fontSize: "12px", color: "GrayText" }}>
            {item?.description}
          </p>
        </Box>

        {isUpdate && (
          <Box display="flex">
            <Tooltip title={"Xoá lịch họp"}>
              <Button
                size="small"
                onClick={() => {
                  toggleConfirmModal({
                    title: `Bạn muốn xoá đăng ký này?`,
                    content: `Đăng ký này sẽ bị xoá sau khi xác nhận`,
                    onSubmit: async () => {
                      if (item?.id) {
                        deleteSchedule.request(
                          MeetingService.deleteMeeting(item.id).then(res => {
                            if (res.success) {
                              toggleMessage({
                                type: "success",
                                message: "Cập nhật thành công"
                              });
                              reFetch();
                            } else {
                              if (res.success) {
                                toggleMessage({
                                  type: "error",
                                  message: res?.error ?? "Đã xảy ra lỗi"
                                });
                                reFetch();
                              }
                            }
                          })
                        );
                      }
                    },
                    submitText: "Xác nhận",
                    type: "warning-red"
                  });
                }}
              >
                <DeleteRoundedIcon />
              </Button>
            </Tooltip>
            {/* <Tooltip title="Chỉnh sửa thông tin">
              <Button
                size="small"
                sx={{ marginLeft: "16px" }}
                onClick={() => setOpenEdit(true)}
              >
                <CreateRoundedIcon />
              </Button>
            </Tooltip> */}
          </Box>
        )}
      </Box>
      <Divider />
      <Box padding="4px 12px" display="flex">
        <Box>
          <RowLayout
            title={"Trạng thái"}
            icon={<BookmarkBorderOutlinedIcon />}
            contentBox={
              <Chip
                label={meetingRoomStatusTitle(item?.status)}
                size="small"
                sx={{
                  backgroundColor: meetingRoomStatusColor(item?.status).bgcolor,
                  color: meetingRoomStatusColor(item?.status).color,
                  fontSize: "10px",
                  fontWeight: "600",
                  height: "20px"
                  // marginLeft: "8px"
                }}
              />
            }
          />
          <RowLayout
            title={"Ngày - Giờ"}
            icon={<EventOutlinedIcon />}
            content={`${CommonHandle.formatDate(item?.startDate)}\t${moment(item?.startDate).format(TIME_FORMAT)}`}
          />
          <RowLayout
            title={"Địa điểm"}
            icon={<MeetingRoomOutlinedIcon />}
            content={item?.meetingRoom.roomName}
          />
        </Box>
        <Divider orientation="vertical" flexItem sx={{ margin: "0 14px" }} />
        <Box>
          <RowLayout
            title={"Người chủ trì"}
            icon={<ManageAccountsOutlinedIcon />}
            content={item?.meetingRegistrant.fullName}
          />
          <RowLayout
            title={"Tham dự"}
            icon={<PeopleAltOutlinedIcon />}
            content={item?.meetingParticipants
              .map(user => user.userNameParticipant)
              .join(", ")}
          />
          <RowLayout
            title={"Tham dự khác"}
            icon={<AccountCircleOutlinedIcon />}
            content={item?.otherMeetingParticipants}
          />
        </Box>
      </Box>
      <Divider />
      {isCancel && (
        <TextField
          placeholder="Nhập lý do"
          sx={{ margin: "12px", width: "calc(100% - 24px)" }}
          value={inputValue}
          size="small"
          onChange={e => {
            setInputValue(e.target.value);
          }}
        />
      )}
      {(isAdminUpdate || (!isAdminUpdate && isRegister)) && (
        <Box display="flex" marginBottom="8px" justifyContent="end">
          {actionLeft && (
            <Button
              variant={actionLeft?.variant}
              color={actionLeft?.color}
              size="small"
              className="event-action-button"
              onClick={() => actionLeft.onClick()}
            >
              {actionLeft?.text}
            </Button>
          )}
          {actionRight && (
            <Button
              variant={actionRight?.variant}
              color={actionRight?.color}
              size="small"
              className="event-action-button"
              onClick={() => actionRight.onClick()}
            >
              {actionRight?.text}
            </Button>
          )}
        </Box>
      )}
    </Wrapper>
  );
};

export default EventDetailPopover;
